/* eslint-disable  */

import { Box, makeStyles, Button, IconButton, TextField, Typography, Divider, Paper, Tabs, Tab, FormControlLabel, Switch, Tooltip,CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AlertContext } from '../Contexts/AlertContext/AlertContext';
import { useStyles } from "./styles"
import { getTestsByIdocId, getTestResultsByIdocId } from "../../services/iDocSessionsService"
import { getHistoricValuesByTestResultId, getTestByTestMethodId, getSampleTypeTestSpecifications } from "../../services/TestsService"

import { updateResult, insertResult } from "../../services/ReportsService"
import { uploadResultImage, getResultImages, getResultVideos } from '../../services/FileService'
import CommentIcon from '@mui/icons-material/ChatBubbleOutline';
import EditCellDialog from "./EditCellDialog"
import InsertCellDialog from "./InsertCellDialog";
import DiagramComponent from "./DiagramComponent"
import ImagesComponent from "./ImagesComponent"
import VideosComponent from "./VideosComponent"
import { Dialog,  DialogTitle, DialogActions, DialogContentText, DialogContent } from '@material-ui/core';
import DefectIcon from '@mui/icons-material/GppBadOutlined';
import { generateChartData } from './ResultsCharts'
import { minWidth } from '@mui/system';
import { getRealmUsers } from '../../services/KeycloakAdminAPI';

const EditableResultsTable = (props) => {   // Test Results Table....

    const { iDoc, job, dense, setGraphData, setGraphOptions } = props;

    const [title, setTitle] = useState("");
    const [rows, setRows] = useState([]);
    const [cols, setCols] = useState([]);
    const [timeoutElapsed, setTimeoutElapsed] = useState(false);
    const classes = useStyles();
    var poller = null;
    const [EditCellDialogState, setEditCellDialogState] = useState({ shown: false, callback: () => { } })
    const [InsertCellDialogState, setInsertCellDialogState] = useState({ shown: false, callback: () => { } })
    const [alertContext, setAlertContext] = useContext(AlertContext);
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [defectsDialog, setDefectsDialog] = useState({show: false, title:"", defects:[]})
    const { t } = useTranslation();
    const [resultsLength, setResultsLength] = useState(0);
    const [tooltipTitles, setTooltipTitles] = useState({});

    const _ = require("lodash");
    
    const clrInterval = () => {
        if (poller !==null) clearInterval(poller);
        poller = null;
    }

    


    useEffect(() => {
        setTitle(`${iDoc.testMethod}`)
        refreshTable();
        return () => { clrInterval() }
    }, [])



    useEffect(() => {
        setSideBarContent();
    }, [images, videos])



    const setSideBarContent = () => {
        props.setSideBarContent(
            <React.Fragment>
                {iDoc ? <DiagramComponent iDoc={iDoc} job={job}/> : null}
                <ImagesComponent images={images} setImages={setImages} />
                <VideosComponent videos={videos} setVideos={setVideos} />
            </React.Fragment>
        )
    }

    


    const refreshTable = () => {
        getHistoricalValues();
        clrInterval();
        populateTable();
        if (poller === null) poller = setInterval(() => { populateTable() }, 5000)
    }

    const colors = {
        // Orange colour fine-tuned for readibility.
        pending: "black",
        rejected: "grey",
        passed: "green",
        above_max: "red",
        below_min: "red",
        above_max_within_tolerance: "#cd7000",
        below_min_within_tolerance: "#cd7000",
        retest: "#cd7000",
        no_spec: "black",
        ignore: "#dddddd",
		absent:"white"
    }



    const getImageFileName = (img) => {
        let ext = img.key.split('.').pop();
        let fileName = "";
        if (img.global)  fileName = img.testName + "_global." + ext     
        else {
            let temp = ".";
            if (img.sequence > 1) temp = "(" + img.sequence + ")."
            fileName = img.uniqueId + "." + img.testName + temp + ext
        }
        fileName = fileName.replace(/ /g, '');
        return fileName;
    }



    const getTestResultImages = () => {
        getResultImages(iDoc.id).then((res) => {
            if (res.req_error) return;
            let imgs = res.map((img) => {
                var fileName = getImageFileName(img);
                return { bytes: img.image, key: img.key, name: fileName, id: img.id, global: img.global }
            })
            imgs.sort((a, b) => {
                return a.name > b.name;
            });
            setImages(imgs)
        })
    }


    const getTestResultVideos = () => {
        getResultVideos(iDoc.id).then((res) => {
            if (res.req_error) return;
            let vids = res.map((vid) => {
                var fileName = getImageFileName(vid);
                return { bytes: vid.video, key: vid.key, name: fileName, id: vid.id}
            })
            vids.sort((a, b) => {
                return a.name > b.name;
            });
            setVideos(vids)
        })
    }



    const showDefectsDialog = (sampleName, defects) => {
        setDefectsDialog({ show: true, title: `"${sampleName}"`, defects: defects})
    }

   

    const moveToNext = (rowIndex, colIndex,  resultsLength) => {
        let nextRowIndex = rowIndex + 1;
        let nextColIndex = colIndex;
        if (nextRowIndex === resultsLength) {
            nextRowIndex = 0;
            nextColIndex++;
        }
        setTimeout(() => {
            try {
                document.getElementById(`${nextRowIndex}_${nextColIndex}`).click();
            } catch (err) { }
        }, 10)
    }

    const getHistoricalValues = async () => {
        var spacing = 15;

        getTestsByIdocId(iDoc.id,localStorage.getItem("language")).then((tests) => {
            if (tests.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }
            
            var testIds = tests.map((test) => { return test.id })

            getTestResultsByIdocId(iDoc.id).then((results) => {
                if (results.req_error) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }

                if (results.length > 0) {
                    results.map((r) => {                      
                        testIds.forEach((testId) => {
                            var resultObj = r.results.find((res) => { return res.testId === testId })
                            if (resultObj === undefined) return;

                            getHistoricValuesByTestResultId(resultObj.testResultId).then((res) => {
                                if (res.req_error) return;

                                setTooltipTitles(tooltipTitles[resultObj.testResultId] = 
                                    (<div style={{ fontSize: "12px", justifyContent: "center", textAlign: "center" }}>{res.length === 0 ? "No value history to show..." : 
                                        <div style={{ fontWeight: "bold" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "14px", padding: "10px" }}>Value History<br /></span>
                                            <div style={{ display: "inline-block", padding: `${spacing}px`, paddingTop: "0px", paddingBottom: "0px" }}>
                                                <span style={{ fontWeight: "bold"  }}>{t("Made At")}</span><br />
                                                {res.slice(0).reverse().map((testResultValue) => { return <span>{new Date(testResultValue.madeAt).toLocaleDateString("en-UK")}<br /></span> })}
                                               
                                            </div>
                                            <div style={{ display: "inline-block", padding: `${spacing}px`, paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px" }}>
                                                <span style={{ fontWeight: "bold" }}>{t("Made By")}</span><br />
                                                {res.slice(0).reverse().map((testResultValue) => { return <span>{testResultValue.updatedBy === null || testResultValue.updatedBy === undefined ? "N/A" : testResultValue.updatedBy}<br /></span> })}
                                            </div>
                                            <div style={{ display: "inline-block", padding: `${spacing}px`, paddingTop: "0px", paddingBottom: "0px" }}>
                                                <span style={{ fontWeight: "bold" }}>{t("Value")}</span><br />
                                                {res.slice(0).reverse().map((testResultValue) => { 
                                                    if (testResultValue.passOrFail) return <span>{testResultValue.value === 1 ? "pass" : "fail"}<br /></span> 
                                                    else return <span>{(testResultValue.value - Math.floor(testResultValue.value)) !== 0 ? testResultValue.value : testResultValue.value + ".00"}<br /></span>
                                                })}
                                            </div>
                                        </div>   
                                    }</div>))
                            })
                        })
                    })
                }
            })
        })
    }

    const getSpecsheader = (min, max, tolMin, tolMax, nom) => {
        //   {min !== null && nom !== null && <td> (tol -{tolMin}%)</td>}
        // {max !== null && nom !== null && <td> (tol +{tolMax}%)</td>}
        return (
         <table>
            <tr>
                <td>
                    <span style={{ color: "blue" }}>{t("min")}:</span> {min !== null && min !== max ? Math.round(min * 100) / 100 : "n/a"}
                </td>
              
            </tr>
            <tr>
                <td>
                    <span style={{ color: "Orange" }}>{t("max")}:</span> {max !== null && min !== max ? Math.round(max * 100) / 100 : "n/a"}
                </td>
               
            </tr>
         </table>

        )

    }

    const populateTable = () => {
        setTimeoutElapsed(false)
        setTimeout(() => { setTimeoutElapsed(true) }, 3000);
        getTestResultImages()
        getTestResultVideos()
        getTestsByIdocId(iDoc.id,localStorage.getItem("language")).then((tests) => {
            if (tests.req_error) {
                setAlertContext({ ...alertContext, open: true });
                return;
            }

            if (tests.length === 0) return;

            var temp = tests.find((t) => { return t.name.toLowerCase() === "cavity number" })
            if (temp) {
                tests = tests.filter((t) => { return t.id !== temp.id })
                tests.push(temp)
            }
           

            var testIds = tests.map((test) => { return test.id })

            var columns = tests.map((test, colIndex) => {
                return { selector: (row) => row[colIndex], sortable: false, name: test.name, width:"250px",type: "text", key: "K" + colIndex }
            })  

            columns.unshift({ selector: (row) => row["sample"], sortable: true, name: "Sample", width: "250px", type: "text", key: "Sample" })

            setCols(columns);
          
            const getCell = (resultObj, sampleName, cellCoords, resultsLength) => {
                let rowIndex = cellCoords.rowIndex;
                let colIndex = cellCoords.colIndex;
                let result = resultObj.result;  // pass / fail, etc
                let value = resultObj.hasOwnProperty('value') ? resultObj.value : null;
                let testResultId = resultObj.testResultId
                let test = tests.find((test) => { return test.id === resultObj.testId });
                let dataType = test.testDataType

                if (value !== null) {
                    if (dataType === "Pass/Fail") 
					{
                        if (value === 0) {
							value = "fail"
							}
                        else {
							value = "pass"
							}
						if (resultObj.result=="ignore") value="-";	
						if (resultObj.result=="absent") value="";	
                        if (value === "fail") result = "above_max"
                        else result = "passed"
                    }
                }
                else value = "null";
                let comment = resultObj.comment;
                let ignore = resultObj.ignore;

                var rtn = null;
                var nonEditable =resultObj.result=="absent";// false;
                if (sampleName === "Min" || sampleName === "Max" || sampleName === "Avg") {
                    nonEditable = true;
                }

                if (nonEditable) {
					rtn = <span style={{ color: "gray" }}><em>{value}</em></span>
					}
                else rtn = <a id={`${rowIndex}_${colIndex}`} style={{ cursor: "pointer", color: colors[result] ? colors[result] : "black" }}
                    onClick={() => {
                        if (job.status === "completed_accepted") return;
                        setEditCellDialogState({
                            shown: true,
                            value: value,
                            comment: comment,
                            ignore: ignore,
                            dataType: dataType,
                            info: <span><b>Sample ID:</b> {sampleName}<br /><b>Test:</b> {test.name}</span>,
                            callback: (v, c, fileData, universal, tabClicked) => {
                                if (v === -1 && c === "cancel_clicked") return;
                                if ( c === "ignore_clicked") {
									fileData=[];
									ignore=true;
									comment=null;
									}
									else
									{
									ignore=false;										
									}
                                let d = { testResultId: testResultId, value: v, comment: c, ignore :ignore }

                                updateResult(d).then((res) => {
                                    if (res) {
                                        if (fileData.length > 0) {
                                            uploadResultImage(testResultId, fileData[0], universal).then((res) => {
                                                if (res.image) refreshTable()
                                            })
                                        }
                                        else refreshTable();
                                    }
                                    if (tabClicked) {
                                        moveToNext(rowIndex, colIndex, resultsLength)
                                    }
                                })
                            }
                        })
                    }}>{<Tooltip style={{ width: "200px" }} title={tooltipTitles[testResultId]}><span>{value}</span></Tooltip>}
                    {comment != null && comment.length > 0 && <Tooltip title={<span style={{ fontSize: "12px" }}>{comment}</span>}><CommentIcon style={{ height: "15px", color: "black" }} /></Tooltip>}
                </a>

                return rtn;
            }

            const getEmptyCell = (testId, sample, cellCoords, resultsLength, resultObj) => {
                let rowIndex = cellCoords.rowIndex;
                let colIndex = cellCoords.colIndex;
                let sampleId = sample.id;
                let sampleName = sample.uniqueId;
                var nonEditable = false;
                if (sampleName === "Min" || sampleName === "Max" || sampleName === "Avg") {
                    nonEditable = true;
                }
				if (resultObj!=null && resultObj.result=="absent")
				{
					nonEditable = true;						
				}
                let test = tests.find((test) => { return test.id === testId });
                let dataType = test.testDataType
                var rtn = null;
                if (nonEditable) rtn = <span style={{ color: "gray" }}><em>n/a</em></span>;
                else rtn =  <a id={`${rowIndex}_${colIndex}`} style={{ cursor: "pointer", color: "green" }}
                    onClick={() => {
                        if (job.status === "completed_accepted") return;
                        setInsertCellDialogState({
                            shown: true,
                            value: "", comment: "",
                            dataType: dataType,
                            info: <span><b>Sample ID:</b> {sampleName}<br/><b>Test:</b> {test.name}</span>,
                            callback: (v, c, tabClicked) => {
                                if (v === -1 && c === "cancel_clicked") return;
                                let d = { sampleId: sampleId, testId: testId, value: v, comment: c }
                                insertResult(d).then((res) => {
                                    if (res) refreshTable();
                                })
                               
                                if (tabClicked) {
                                    moveToNext(rowIndex, colIndex, resultsLength)
                                }
                            }
                        })
                    }}> -----
                </a>

                return rtn;
            }



            const getSampleNameCell = (sample) => {
                if (!(sample && sample.sample)) return null;
                let defects = sample.sample.defects ? sample.sample.defects.map((d) => {
                    return { name: d.defect.name, severity: d.severity}
                }) : []
                var name = sample.sample.uniqueId ? sample.sample.uniqueId : ""
                if (name === "Min" || name === "Max" || name === "Avg") return <span style={{ color: "gray" }}><em>{name}. result value:</em></span> 
                if (defects.length === 0) return name
                else {
                   let color = "black";
                   if (defects.filter((d) => { return d.severity == "major" }).length > 0) color = "orange"
                   if (defects.filter((d) => { return d.severity == "critical" }).length > 0) color = "red"
                    return (
                        <span>
                            <Tooltip title={<div style={{ fontSize: "12px" }}>{t("This sample has")} {defects.length} {t("defect(s)")}. <br/> {t("Click to view")}.</div>}>
                                <a style={{ cursor: "pointer", color: color, marginRight: "20px" }} onClick={() => { showDefectsDialog(name, defects) }}>
                                    <span>{name}</span>
                                    <DefectIcon style={{ height: "20px", color: color, marginLeft: "10px" }} />  
                                </a>
                            </Tooltip>
                        </span>
                    )
                }
            }


            getTestResultsByIdocId(iDoc.id).then( (results) => {

                if (results.req_error) {
                    setAlertContext({ ...alertContext, open: true });
                    return;
                }
                var tableRows = [];
               
                if (results.length > 0) {
                   
                    var specsObj = {}
                    let specRow = { sample: null };

                    getSampleTypeTestSpecifications(results[0].sampleType.id).then((res) => {
                       

                        testIds.map((testId, colIndex) => {
                            var specs = res.find((spec) => { return spec.testId === testId })
                            specsObj[testId] = specs
                            var test = tests.find((t) => { return t.id === testId })
                            if (test.testDataType === "Pass/Fail") specRow[colIndex] = ""; 
                            else if (specs) {
                                var min = specs.minUnit
                                var max = specs.maxUnit
                                var tolMin = specs.minTolerance
                                var tolMax = specs.maxTolerance
                                var nom = specs.nominalValue
//                                console.log(specs)
                                specRow[colIndex] = getSpecsheader(min, max, tolMin, tolMax, nom);
                            }
                            else specRow[colIndex] = ( <table><tr><td>{t("min")}: n/a </td></tr><tr><td>{t("max")}: n/a</td></tr></table> )
                        })



                        tableRows = results.map((r, rowIndex) => {
                            let row = { sample: getSampleNameCell(r) };

                            testIds.forEach((testId, colIndex) => {
                                var resultObj = r.results.find((res) => { return res.testId === testId })
								
                                if (resultObj && resultObj.result!="absent") {
									row[colIndex] = getCell(resultObj, r.sample.uniqueId, { rowIndex, colIndex }, results.length);
									}
                                else {
									row[colIndex] = getEmptyCell(testId, r.sample, { rowIndex, colIndex }, results.length,resultObj);
									}
                            })
                            return row;
                        });

                        tableRows.unshift(specRow)

                        if (tableRows.length > 0) {
                            setRows(tableRows);
                            console.log("Test results table refreshed!!")
                        }

                      
                        var _results = results.filter((r) => {
                            if (r.sample && r.sample.uniqueId) return r.sample.uniqueId !== "Min" && r.sample.uniqueId !== "Max" && r.sample.uniqueId !== "Avg"
                            else return true
                        })

                        
                        generateChartData(tests, _results, setGraphData, setGraphOptions, iDoc.testMethod, specsObj, refreshTable, t)  //
                      //  setGraphData(res.graphData)
                       // setGraphOptions(res.options)

                    })
 
                }

               
            });

        })

    }


    return (
        <React.Fragment>
            {rows.length > 0 || timeoutElapsed === true ? <Paper className={classes.paper}>
                <div style={{ textAlign: "center", paddingTop: "40px", marginBottom: "20px" }}>
                    {t("Editable results table for")}: <b>{title}</b>.<br /><br /><span style={{ color: "grey" }}>
                        {t("Click on any result value to edit it, add a comment and / or an image file for that result.")}<br />
                        {t("(Tip. When done, you may save and close the pop-up, OR press ESC to save and move to next sample.)")}
                    </span>
                </div>

                <div style={{ overflowX: 'auto', maxWidth: "60vw" }}>
                    <DataTable id="EditableResultsTable"
                        customStyles={{

                            headCells: { style: { fontWeight: 'bold', fontSize: "15px" }, }
                        }}
                        striped
                        columns={cols}
                        data={rows}
                        dense={+dense}
                        clearSelectedRows={() => { }}
                    />
                </div>


            </Paper>: null}

            {EditCellDialogState.shown && 
            <EditCellDialog
                show={EditCellDialogState.shown}
                value={EditCellDialogState.value}
                comment={EditCellDialogState.comment}
                dataType={EditCellDialogState.dataType}
                info={EditCellDialogState.info}
                ignore={EditCellDialogState.ignore}
                callback={
                    (val, comment, fileData, universal, tabClicked,ignore) => {
                        setEditCellDialogState({ ...EditCellDialogState, shown: false });
                        EditCellDialogState.callback(val, comment, fileData, universal, tabClicked, ignore);
                        
                    }
                }
                />}

            {InsertCellDialogState.shown &&
                <InsertCellDialog
                    show={InsertCellDialogState.shown}
                    value={InsertCellDialogState.value}
                    comment={InsertCellDialogState.comment}
                    dataType={InsertCellDialogState.dataType}
                    info={InsertCellDialogState.info}
                    callback={
                        (val, comment, tabClicked) => {
                            setInsertCellDialogState({ ...InsertCellDialogState, shown: false });
                            InsertCellDialogState.callback(val, comment, tabClicked);
                            
                        }
                    }
                />}

            <Dialog
                fullWidth
                maxWidth="sm"
                open={defectsDialog.show}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {defectsDialog.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {defectsDialog.defects.length} defect(s):
                    </DialogContentText>
                    <table style={{width:"100%"}}>
                        <tr style={{ textAlign: "left" }}>
                            <th>Defect</th>
                            <th>Severity</th>
                        </tr>
                        {defectsDialog.defects.map((d) => {
                            let color = "black";
                            if (d.severity === "critical") color = "red"
                            if (d.severity === "major") color = "orange"
                            return <tr>
                                <td>{d.name}</td>
                                <td style={{color : color}}>{d.severity}</td>
                            </tr>
                    })}
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { setDefectsDialog({ show: false, defects: [], title: ""}) }} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            </React.Fragment>
    )

}





export default EditableResultsTable;